import { ResultsCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
  showConsolidatedResults?: boolean;
};

export const MY_RESULTS_REQUESTED = 'MY_RESULTS_REQUESTED' as const;
export const requestMyResults = (options: Options) => ({
  type: MY_RESULTS_REQUESTED,
  payload: { options },
});

export const MY_RESULTS_RECEIVED = 'MY_RESULTS_RECEIVED' as const;
export const myResultsReceived = (list: ResultsCollection | null, page: number) => ({
  type: MY_RESULTS_RECEIVED,
  payload: { list, page },
});

export type MyResultsReceivedAction = ReturnType<typeof myResultsReceived>
export type MyResultsAction = ReturnType<typeof requestMyResults> | MyResultsReceivedAction;
