import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink, Link } from 'components/primitives/links';
import { useSelector, shallowEqual } from 'react-redux';
import LogoutButton from './LogoutButton';
import { routesBuilder } from 'routes';
import { useCurrentRouteAsBackTo } from 'utils/hooks';

const CustomerMenuItems = ({ logoutDelay }) => {

  const { name, isMainDealer } = useSelector(({ user }) => ({
    name: user.name,
    isMainDealer: user.isMainDealer,
  }), shallowEqual);

  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      {isMainDealer &&
        (<Link to={routesBuilder.forRepresentSubDealer} options={{ backTo }} className={styles.item}>
          <SimpleText textKey="RepresentSubDealer" />
        </Link>)}
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

CustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default CustomerMenuItems;
