//3.3.My account � Open order lines, status update
import { STATUSUPDATE_RECEIVED_RESULT, STATUSUPDATE_SUBMITTED, STATUSUPDATE_RESET, StatusUpdateAction } from './actions';

type State = {
  statusUpdate: boolean | null;
}

const initialState: State = {
  statusUpdate: null,
};

export default function reducer(state: State = initialState, action: StatusUpdateAction) {
  switch (action.type) {
      case STATUSUPDATE_RECEIVED_RESULT:
      return { ...state, statusUpdate: action.payload };
      case STATUSUPDATE_SUBMITTED:
      return { ...state, statusUpdate: null };
      case STATUSUPDATE_RESET:
      return { ...state, statusUpdate: null };
    default:
      return state;
  }
}
