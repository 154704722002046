//213513_2.1_CarScreenPage
import { ResultsCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
  searchText ?: string;
};

export const CAR_SCREENS_REQUESTED = 'CAR_SCREENS_REQUESTED' as const;
export const requestCarScreens = (options: Options) => ({
  type: CAR_SCREENS_REQUESTED,
  payload: { options },
});

export const CAR_SCREENS_RECEIVED = 'CAR_SCREENS_RECEIVED' as const;
export const carScreensReceived = (list: ResultsCollection | null, page: number) => ({
  type: CAR_SCREENS_RECEIVED,
  payload: { list, page },
});

export type CarScreensReceivedAction = ReturnType<typeof carScreensReceived>
export type CarScreensAction = ReturnType<typeof requestCarScreens> | CarScreensReceivedAction;
