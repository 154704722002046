import { default as createHandler } from './handler';
import {
  ordersPageQuery,
  quotesPageQuery,
  invoicesPageQuery,
  returnOrdersPageQuery,
  creditNotesPageQuery,
  returnReceiptsPageQuery,
  shipmentsPageQuery,
  //____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
  openOrderLinesPageQuery,
  //____ [HyundaiMotorNetherlands] 3.5.My account – Credit note line overview.
  creditNoteLinesPageQuery,
  invoiceLinesPageQuery,//TICKET 131741- 3.4. My account – Sales invoice line history
} from './queries';
import { PageComponentNames } from '../componentNames';
import { DocumentType } from 'behavior/documents';

export { requestDocuments } from './actions';

export { normalizeFilter, createOptions } from './handler';
export const ordersHandler = createHandler(DocumentType.Order, PageComponentNames.Orders, ordersPageQuery);
//____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
export const openOrderLinesHandler = createHandler(DocumentType.OpenOrderLines, PageComponentNames.OpenOrderLines, openOrderLinesPageQuery);
//____ [HyundaiMotorNetherlands] 3.5.My account – Credit note line overview.
export const creditNoteLinesHandler = createHandler(DocumentType.CreditNoteLines, PageComponentNames.CreditNoteLines, creditNoteLinesPageQuery);
export const quotesHandler = createHandler(DocumentType.Quote, PageComponentNames.Quotes, quotesPageQuery);
export const invoicesHandler = createHandler(DocumentType.Invoice, PageComponentNames.Invoices, invoicesPageQuery);
export const returnOrdersHandler = createHandler(DocumentType.ReturnOrder, PageComponentNames.ReturnOrders, returnOrdersPageQuery);
export const creditNotesHandler = createHandler(DocumentType.CreditNote, PageComponentNames.CreditNotes, creditNotesPageQuery);
export const returnReceiptsHandler = createHandler(DocumentType.ReturnReceipt, PageComponentNames.ReturnReceipts, returnReceiptsPageQuery);
export const shipmentsHandler = createHandler(DocumentType.Shipment, PageComponentNames.Shipments, shipmentsPageQuery);

export const invoiceLinesHandler = createHandler(DocumentType.InvoiceLines, PageComponentNames.InvoiceLines, invoiceLinesPageQuery);

