import type { Api } from 'utils/api';
import type { Handler } from '../types';
import type { CustomerCollectionData, CustomerCollection } from './types';
import { representationPage } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { RouteName } from 'routes';

type RepresentRouteData = {
    routeName: RouteName.RepresentSubDealer;
    params?: {
        previewToken?: string;
    };
};

type RepresentSystemPageResponse = {
    pages: {
        representSubDealer: SystemPageData;
    };
};

type RepresentPageResponse = RepresentSystemPageResponse & {
    profile: {
        impersonationSubDealer: {
            customers: CustomerCollectionData;
        };
    };
};

type RepresentSubDealerPage = SystemPage & {
    component: PageComponentNames.RepresentDealer;
    customers: CustomerCollection;
};

const handler: Handler<RepresentRouteData, RepresentSubDealerPage> = ({ params }, _state$, { api }) => {
    if (params?.previewToken)
        return handlePreview(api);
    return api.graphApi<RepresentPageResponse>(representationPage).pipe(
        map(({ pages, profile: { impersonationSubDealer } }) => {
            const page = pages.representSubDealer;
            if (!impersonationSubDealer || !page)
                return null;

            const result = {
                page: initPageContent(page) as RepresentSubDealerPage,
            };
            result.page.component = PageComponentNames.RepresentDealer;
            result.page.customers = impersonationSubDealer.customers;
            result.page.customers.keywords = '';

            return result;
        }),
    );
};

export default handler;

function handlePreview(api: Api) {
    return api.graphApi<RepresentSystemPageResponse>(loadSystemPageQuery('represent-sub-dealers')).pipe(
        map(({ pages: { representSubDealer: page } }) => {
            if (!page)
                return null;
            const result = {
                page: initPageContent(page) as RepresentSubDealerPage,
            };

            result.page.component = PageComponentNames.RepresentDealer;
            result.page.customers = {
                keywords: '',
                total: 5,
                list: Array.from(Array(5)).map((_, index) => ({
                    id: index.toString(),
                    name: '',
                    address: { postalAddress: '' },
                })),
            };

            return result;
        }),
    );
}
