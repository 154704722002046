//131754 [HyundaiMotorNetherlands] 4.4.	Header – Show consumer prices.
import PropTypes from 'prop-types';
import ConsumerPriceSelector from './ConsumerPriceSelector';

const MobileConsumerPriceSelectorBlock = ({ isDesignerMode }) => {
  return (
    <ConsumerPriceSelector isDesignerMode={isDesignerMode} />
  );
};

MobileConsumerPriceSelectorBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default MobileConsumerPriceSelectorBlock;