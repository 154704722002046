//3.3.My account � Open order lines, status update
type StatusUpdateInput = {
  name: string;
  emailAddress: string;
  comment: string;
  productIdOrdered?: string;
  productIdToDeliver?: string;
  description?: string;
  quantityOrdered?: number;
  quantityToBeDelivered?: number;
  grossPrice?: number;
  lineDiscountPercentage?: number;
  netValueLine?: number;
  orderDate?: string;
  captchaToken?: string;
}

export const STATUSUPDATE_RESET = 'STATUSUPDATE/FORM/RESET' as const;
export const sendStatusReset = () => ({ type: STATUSUPDATE_RESET });

export const STATUSUPDATE_SUBMITTED = 'STATUSUPDATE/FORM/SUBMITTED' as const;
export const sendStatusUpdate = (data: StatusUpdateInput) => ({ type: STATUSUPDATE_SUBMITTED, payload: data });

export const STATUSUPDATE_RECEIVED_RESULT = 'STATUSUPDATE/RESPONSE/RECEIVED' as const;
export const statusUpdateReceived = (success: boolean) => ({
  type: STATUSUPDATE_RECEIVED_RESULT,
  payload: success,
});

export type StatusUpdateAction = ReturnType<
  | typeof sendStatusUpdate
  | typeof statusUpdateReceived
  | typeof sendStatusReset
>
