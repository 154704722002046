import {
  FACET_IMAGE_RECEIVED,
  FacetImageTypeAction,
} from './actions';
import type { FacetImageType } from './types';

export default (state = {}, action: FacetImageTypeAction) => {
  if (action.type === FACET_IMAGE_RECEIVED)
    return onReceivedFacetImage(state, action);

  return state;
};

function onReceivedFacetImage(state: any, { payload }: { payload: FacetImageType }) {
  return {
    ...state,
    facetImage: payload.catalog?.facetsImages.image,
  };
}