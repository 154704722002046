import loadable from '@loadable/component';

const MyResults = loadable(() => import(/*webpackChunkName:"docs"*/'./MyResults'));

function selectPropsFromPage({
  list: {
    items,
    totalCount,
  },
  size,
  filter,
}) {
  return {
    list: items,
    totalCount,
    size,
    filter,
  };
}
// hyundai 3.8
export function myResultsRenderer(page) {
  return <MyResults {...selectPropsFromPage(page)} />;
}