import type { Product } from './types';

 //TICKET 131747 - 3.9. Performance � Catalog items
export const SEARCH_PRODUCT_SUGGESTIONS = 'PRODUCT/SEARCH/SUGGESTION' as const;
export const searchProductsSuggestions = (keywords: string) => ({
  type: SEARCH_PRODUCT_SUGGESTIONS,
  payload: { keywords,isSearch:true },
});

export const SEARCH_PRODUCT_SUGGESTIONS_COMPLETED = 'PRODUCT/SEARCH/SUGGESTION/COMPLETED' as const;
export const productsSearchSuggestionsCompleted = (products: Product[]) => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_COMPLETED,
  payload: products,
});

export const SEARCH_PRODUCT_SUGGESTIONS_CLEARED = 'PRODUCT/SEARCH/SUGGESTION/CLEARED' as const;
export const clearProductsSuggestions = () => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_CLEARED,
});

export type SearchSuggestionsAction = ReturnType<
  | typeof searchProductsSuggestions
  | typeof productsSearchSuggestionsCompleted
  | typeof clearProductsSuggestions
>;
