import{createDocumentsSegment,createLineDocumentsSegment,createDocumentsPageQuery,createDocumentsQuery}from'./queries.helpers';import{DocumentType}from'behavior/documents';var orderSpecificFields='totals{totalPrice}';var quotesSpecificFields='dueDate totals{totalPrice}';var invoicesSpecificFields='orderId orderDate dueDate isPaid totals{outstandingAmount totalPrice} payment{status}';var creditNotesSpecificFields='orderId orderDate totals{outstandingAmount totalPrice}';var returnReceiptsSpecificFields='orderId orderDate';var shipmentsSpecificFields='orderId orderDate';//____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
var openOrderLinesSpecificFields='quantityToBeDelivered orderDate isStatusUpdateAllowed orderStatus';var creditNoteLinesSpecificFields='creditNoteDocumentNo quantityDelivered invoiceDate userId colliNo mobisOrderNumber parentUrl';//TICKET 131741- 3.4. My account – Sales invoice line history
var invoiceLinesSpecificFields='invoiceDocumentNo quantityDelivered invoiceDate userId colliNo mobisOrderNumber parentUrl';var ordersQuerySegment=createDocumentsSegment(DocumentType.Order,orderSpecificFields);//____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
var openOrderLinesQuerySegment=createLineDocumentsSegment(DocumentType.OpenOrderLines,openOrderLinesSpecificFields);//____ [HyundaiMotorNetherlands] 3.5. My account – Credit note line overview.
var creditNoteLinesQuerySegment=createLineDocumentsSegment(DocumentType.CreditNoteLines,creditNoteLinesSpecificFields);var quotesQuerySegment=createDocumentsSegment(DocumentType.Quote,quotesSpecificFields);var invoicesQuerySegment=createDocumentsSegment(DocumentType.Invoice,invoicesSpecificFields);var returnOrdersQuerySegment=createDocumentsSegment(DocumentType.ReturnOrder);var creditNotesQuerySegment=createDocumentsSegment(DocumentType.CreditNote,creditNotesSpecificFields);var returnReceiptsQuerySegment=createDocumentsSegment(DocumentType.ReturnReceipt,returnReceiptsSpecificFields);var shipmentsQuerySegment=createDocumentsSegment(DocumentType.Shipment,shipmentsSpecificFields);//TICKET 131741- 3.4. My account – Sales invoice line history
var invoiceLinesQuerySegment=createLineDocumentsSegment(DocumentType.InvoiceLines,invoiceLinesSpecificFields);export var ordersPageQuery=createDocumentsPageQuery(DocumentType.Order,ordersQuerySegment,'DocumentsLoadOptions');//____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
export var openOrderLinesPageQuery=createDocumentsPageQuery(DocumentType.OpenOrderLines,openOrderLinesQuerySegment,'DocumentsLoadOptions');//____ [HyundaiMotorNetherlands] 3.5. My account – Credit note line overview.
export var creditNoteLinesPageQuery=createDocumentsPageQuery(DocumentType.CreditNoteLines,creditNoteLinesQuerySegment,'DocumentsLoadOptions');export var quotesPageQuery=createDocumentsPageQuery(DocumentType.Quote,quotesQuerySegment,'DocumentsLoadOptions');export var invoicesPageQuery=createDocumentsPageQuery(DocumentType.Invoice,invoicesQuerySegment,'ChildDocumentsLoadOptions');export var returnOrdersPageQuery=createDocumentsPageQuery(DocumentType.ReturnOrder,returnOrdersQuerySegment,'DocumentsLoadOptions');export var creditNotesPageQuery=createDocumentsPageQuery(DocumentType.CreditNote,creditNotesQuerySegment,'ChildDocumentsLoadOptions');export var returnReceiptsPageQuery=createDocumentsPageQuery(DocumentType.ReturnReceipt,returnReceiptsQuerySegment,'ChildDocumentsLoadOptions');export var shipmentsPageQuery=createDocumentsPageQuery(DocumentType.Shipment,shipmentsQuerySegment,'ChildDocumentsLoadOptions');//TICKET 131741- 3.4. My account – Sales invoice line history
export var invoiceLinesPageQuery=createDocumentsPageQuery(DocumentType.InvoiceLines,invoiceLinesQuerySegment,'DocumentsLoadOptions');export var ordersQuery=createDocumentsQuery('Orders',ordersQuerySegment,'DocumentsLoadOptions');//____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
export var openOrderLinesQuery=createDocumentsQuery('OpenOrderLines',openOrderLinesQuerySegment,'DocumentsLoadOptions');//____ [HyundaiMotorNetherlands] 3.5. My account – Credit note line overview.
export var creditNoteLinesQuery=createDocumentsQuery('CreditNoteLines',creditNoteLinesQuerySegment,'DocumentsLoadOptions');export var quotesQuery=createDocumentsQuery('Quotes',quotesQuerySegment,'DocumentsLoadOptions');export var invoicesQuery=createDocumentsQuery('Invoices',invoicesQuerySegment,'ChildDocumentsLoadOptions');export var returnOrdersQuery=createDocumentsQuery('ReturnOrders',returnOrdersQuerySegment,'DocumentsLoadOptions');export var creditNotesQuery=createDocumentsQuery('CreditNotes',creditNotesQuerySegment,'ChildDocumentsLoadOptions');export var returnReceiptsQuery=createDocumentsQuery('ReturnReceipts',returnReceiptsQuerySegment,'ChildDocumentsLoadOptions');export var shipmentsQuery=createDocumentsQuery('Shipments',shipmentsQuerySegment,'ChildDocumentsLoadOptions');//TICKET 131741- 3.4. My account – Sales invoice line history
export var invoiceLinesQuery=createDocumentsQuery('InvoiceLines',invoiceLinesQuerySegment,'DocumentsLoadOptions');export var payInvoicesMutation=" mutation PayInvoices($invoices:[InvoiceInfoInput!]!){ documents{ payInvoices(invoices:$invoices){orderId} } }";