import type { FacetImageListType, FacetImageType } from './types';

export const FACET_IMAGE_REQUEST = 'FACET_IMAGE_REQUEST' as const;
export const requestFacetImage = (request: FacetImageListType) => ({
    type: FACET_IMAGE_REQUEST,
    payload: request,
});

export const FACET_IMAGE_RECEIVED = 'FACET_IMAGE_RECEIVED' as const;
export const receivedFacetImage = (result: FacetImageType) => ({
    type: FACET_IMAGE_RECEIVED,
    payload: result,
});

export type FacetImageTypeAction = ReturnType<
    | typeof requestFacetImage
    | typeof receivedFacetImage
>;