import type { CustomerCollection } from './types';

export const DEALERS_REQUESTED = 'REPRESENTATION/DEALER/REQUESTED' as const;
export const requestDealers = (keywords = '', index = 0, size = 10) => ({
  type: DEALERS_REQUESTED,
  payload: { keywords, page: { index, size } },
});

export const DEALERS_RECEIVED = 'REPRESENTATION/DEALER/RECEIVED' as const;
export const dealersLoaded = (customers: CustomerCollection, keywords: string) => ({
  type: DEALERS_RECEIVED,
  payload: { customers, keywords },
});

export type RepresentationAction = ReturnType<
    | typeof requestDealers
    | typeof dealersLoaded
>;
