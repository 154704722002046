import { AbilityTo } from 'behavior/user/constants';

export const enum DocumentType {
  Order = 'Order',
  //____ [HyundaiMotorNetherlands] 3.2. My account � Open order lines.
  OpenOrderLines = 'OpenOrderLines',
  //____ [HyundaiMotorNetherlands] 3.5.My account � Credit note line overview.
  CreditNoteLines = 'CreditNoteLines',
  Quote = 'Quote',
  Invoice = 'Invoice',
  ReturnOrder = 'ReturnOrder',
  ReturnReceipt = 'ReturnReceipt',
  Shipment = 'Shipment',
  CreditNote = 'CreditNote',
  InvoiceLines = 'InvoiceLines',//TICKET 131741- 3.4. My account � Sales invoice line history
}

export const DocumentAbilitiesMap = {
  [DocumentType.Order]: AbilityTo.ViewOrders,
  //____ [HyundaiMotorNetherlands] 3.2. My account � Open order lines.
  [DocumentType.OpenOrderLines]: AbilityTo.ViewOpenOrderLines,
  //____ [HyundaiMotorNetherlands] 3.5.My account � Credit note line overview.
  [DocumentType.CreditNoteLines]: AbilityTo.ViewCreditNoteLines,
  [DocumentType.Quote]: AbilityTo.ViewQuotes,
  [DocumentType.Invoice]: AbilityTo.ViewInvoices,
  [DocumentType.ReturnOrder]: AbilityTo.ViewReturnOrders,
  [DocumentType.ReturnReceipt]: AbilityTo.ViewReturnReceipts,
  [DocumentType.Shipment]: AbilityTo.ViewShipments,
  [DocumentType.CreditNote]: AbilityTo.ViewCreditNotes,
  [DocumentType.InvoiceLines]: AbilityTo.ViewInvoiceLines,//TICKET 131741- 3.4. My account � Sales invoice line history
} as const;

export const enum ReorderResult {
  Success = 'SUCCESS',
  BasketNotEmpty = 'BASKET_NOT_EMPTY',
  NoProducts = 'NO_PRODUCTS',
  OrderNotFound = 'ORDER_NOT_FOUND',
}

export const enum EditResult {
  Success = 'SUCCESS',
  BasketNotEmpty = 'BASKET_NOT_EMPTY',
  NoDocument = 'NO_DOCUMENT',
}

export const enum QuoteStatus {
  Expired = 'Expired',
  InReview = 'InReview',
  Orderable = 'Orderable',
  Cancelled = 'Cancelled',
}

export const enum PromoteQuoteStatus {
  Succeed = 'SUCCEED',
  CreditLimitForceOnlinePayment = 'CREDIT_LIMIT_FORCE_ONLINE_PAYMENT',
  CreditLimitWarning = 'CREDIT_LIMIT_WARNING',
  CreditLimitError = 'CREDIT_LIMIT_ERROR',
  OutOfStock = 'OUT_OF_STOCK',
  ContainsNonOrderableProducts = 'CONTAINS_NON_ORDERABLE_PRODUCTS',
  QuoteNotFound = 'QUOTE_NOT_FOUND',
}

export const ChildDocumentTypes = [
  DocumentType.Invoice,
  DocumentType.ReturnReceipt,
  DocumentType.CreditNote,
  DocumentType.Shipment,
] as const;
