interface CurrencyInfoBase {
    cultureName: string;
}

interface CurrencyInfo extends CurrencyInfoBase {
    id: string;
    decimalDigits: number;
    symbol?: string;
}

const defaultDecimals = 2;

export const formatAsPrice = (price: number, currencyInfo: CurrencyInfo) =>
    getFormatPrice(currencyInfo)(price);

export const getCurrencyAndNumber = (price: number, currencyInfo: CurrencyInfo | null | undefined): [string, string] => {
    const decimals = currencyInfo ? currencyInfo.decimalDigits : defaultDecimals;
    const normalizedPrice = roundDecimalAwayFromZero(price, decimals);

    const cultureName = currencyInfo ? currencyInfo.cultureName : '';
    const formatter = getNumberFormatter(cultureName, decimals);

    const currencySymbol = currencyInfo && currencyInfo.symbol || '';
    const formattedPrice = formatter
        ? formatter.format(normalizedPrice)
        : toFixed(normalizedPrice, decimals);
    return [currencySymbol, formattedPrice];
};

export const formatAsPercentage = (
    percentage: number,
    cultureOrCurrencyInfo: string | CurrencyInfoBase | null | undefined,
    includePercent = false,
    minimumDecimals?: number,
) => {
    const cultureName = typeof cultureOrCurrencyInfo === 'string'
        ? cultureOrCurrencyInfo
        : cultureOrCurrencyInfo && cultureOrCurrencyInfo.cultureName || '';

    return getFormatPercentage(cultureName, minimumDecimals)(percentage, includePercent);
};

export function getFormatPrice(currencyInfo: CurrencyInfo, includeCurrency = true) {
    const { id: currencyId, decimalDigits: decimals, cultureName, symbol } = currencyInfo;

    const formatter = includeCurrency
        ? getCurrencyFormatter(cultureName, currencyId, decimals)
        : getNumberFormatter(cultureName, decimals);

    return (price: number): string => {
        const normalizedPrice = roundDecimalAwayFromZero(price, decimals);
        if (formatter) {
            if (!symbol)
                return formatter.format(normalizedPrice);

            return formatter.formatToParts(normalizedPrice)
                .map(({ type, value }) => {
                    switch (type) {
                        case 'currency':
                            return symbol;
                        default:
                            return value;
                    }
                })
                .reduce((result, part) => result + part);
        }

        const formattedPrice = toFixed(normalizedPrice, decimals);
        if (!includeCurrency)
            return formattedPrice;

        const currency = symbol || currencyId;
        return `${currency} ${formattedPrice}`;
    };
}

export function getFormatPercentage(cultureName: string, minimumDecimals = 0) {
    const formatter = getPercentFormatter(cultureName, defaultDecimals, minimumDecimals);

    return (percentage: number, includePercent = false): string => {
        if (formatter) {
            const formatted = formatter.format(percentage / 100);
            return includePercent ? formatted : formatted.replace('%', '');
        }

        const normalizedPercentage = roundDecimalAwayFromZero(percentage / 100, defaultDecimals + 2) * 100;
        const formatted = toFixed(normalizedPercentage, defaultDecimals);
        if (includePercent)
            return formatted + '%';
        return formatted;
    };
}

export function getFormatPercentageForOneDecimal(cultureName: string, minimumDecimals = 0) {
    const formatter = getPercentFormatter(cultureName, 1, minimumDecimals);

    return (percentage: number, includePercent = false): string => {
        if (formatter) {
            const formatted = formatter.format(percentage / 100);
            return includePercent ? formatted : formatted.replace('%', '');
        }

        const normalizedPercentage = roundDecimalAwayFromZero(percentage / 100, 1 + 2) * 100;
        const formatted = toFixed(normalizedPercentage, 1);
        if (includePercent)
            return formatted + '%';
        return formatted;
    };
}

export function getFormatNumber(cultureName: string, minimumDecimals = 0) {
    const formatter = getNumberFormatter(cultureName, minimumDecimals);

    if (formatter)
        return formatter.format;

    return (number: number): string => toFixed(number, minimumDecimals);
}

export const getNumberDecimalsSeparator = (function () {
    const cache: Record<string, string | undefined> = {};

    return (culture: string): string => {
        const cached = cache[culture];
        if (cached)
            return cached;

        const formatter = getNumberFormatter(culture);
        if (formatter && formatter.formatToParts) {
            for (const part of formatter.formatToParts(0.1))
                if (part.type === 'decimal')
                    return cache[culture] = part.value;
        }

        return cache[culture] = '.';
    };
})();

function isIntlSupported(cultureName: string) {
    return !!cultureName &&
        typeof Intl !== 'undefined' &&
        Intl.NumberFormat.supportedLocalesOf(cultureName).length > 0;
}

const roundDecimalAwayFromZero = function () {
    const cache = new Map<number, number>();
    const getDiffForNegativeValue = (decimals: number) => {
        let diff = cache.get(decimals);
        if (diff !== undefined)
            return diff;

        diff = -1 / Math.pow(10, decimals + 1);
        cache.set(decimals, diff);

        return diff;
    };

    return function (value: number, decimals: number) {
        const diff = value < 0 ? getDiffForNegativeValue(decimals) : 0;
        return +(Math.round(+((value + diff) + 'e+' + decimals)) + 'e-' + decimals);
    };
}();

const getCurrencyFormatter = function () {
    let cache: Intl.NumberFormat | null;
    let _cultureName: string, _currencyId: string, _decimals: number;
    return _getFormatter;

    function _getFormatter(
        cultureName: string,
        currencyId: string,
        decimals: number = _decimals,
    ): Intl.NumberFormat | null {
        if (_cultureName === cultureName && _currencyId === currencyId && _decimals === decimals)
            return cache;

        void (_cultureName = cultureName, _currencyId = currencyId, _decimals = decimals);
        return cache = isIntlSupported(cultureName)
            ? createIntlNumberFormatter(cultureName, {
                style: 'currency',
                currency: currencyId,
                minimumFractionDigits: decimals,
            })
            : cache = null;
    }
}();

const getNumberFormatter = function () {
    let cache: Intl.NumberFormat | null;
    let _cultureName: string, _minimumDecimals = 0, _maximumFractionDigits: number;
    return _getFormatter;

    function _getFormatter(
        cultureName: string,
        minimumFractionDigits: number = _minimumDecimals,
        maximumFractionDigits: number = _maximumFractionDigits,
    ): Intl.NumberFormat | null {
        if (_cultureName === cultureName && _minimumDecimals === minimumFractionDigits && _maximumFractionDigits === maximumFractionDigits)
            return cache;

        void (_cultureName = cultureName, _minimumDecimals = minimumFractionDigits, _maximumFractionDigits = maximumFractionDigits);
        return cache = isIntlSupported(cultureName)
            ? createIntlNumberFormatter(cultureName, { minimumIntegerDigits: 1, minimumFractionDigits, maximumFractionDigits })
            : cache = null;
    }
}();

const getPercentFormatter = function () {
    let cache: Intl.NumberFormat | null;
    let _cultureName: string, _decimals: number;
    return _getFormatter;

    function _getFormatter(cultureName: string, decimals: number, minimumDecimals: number): Intl.NumberFormat | null {
        if (_cultureName === cultureName && _decimals === decimals)
            return cache;

        void (_cultureName = cultureName, _decimals = decimals);
        return cache = isIntlSupported(cultureName)
            ? createIntlNumberFormatter(cultureName, {
                style: 'percent',
                minimumIntegerDigits: 1,
                minimumFractionDigits: minimumDecimals < decimals ? minimumDecimals : decimals,
                maximumFractionDigits: decimals,
            })
            : cache = null;
    }
}();

function toFixed(value: number, minimumFractionDigits: number, maximumFractionDigits: number = defaultDecimals) {
    if (toLocaleStringSupported)
        return value.toLocaleString(undefined, {
            minimumFractionDigits,
            maximumFractionDigits: Math.max(minimumFractionDigits, maximumFractionDigits),
        });
    return value.toFixed(maximumFractionDigits);
}

function createIntlNumberFormatter(cultureName: string, options?: Intl.NumberFormatOptions) {
    const numberFormat = Intl.NumberFormat(cultureName, options);
    const numberingSystem = numberFormat.resolvedOptions().numberingSystem;

    if (!numberingSystem || numberingSystem === 'latn')
        return numberFormat;

    return Intl.NumberFormat(cultureName + '-u-nu-latn', options);
}

const toLocaleStringSupported = function () {
    const toLocaleStringSupportsLocales = function () {
        const number = 0;
        try {
            number.toLocaleString('i');
        } catch (e) {
            return e.name === 'RangeError';
        }
        return false;
    }();

    if (!toLocaleStringSupportsLocales)
        return false;
    return !!(typeof Intl == 'object' && Intl && typeof Intl.NumberFormat == 'function');
}();
