//131754 [HyundaiMotorNetherlands] 4.4.	Header – Show consumer prices.
import { ISSHOWCONSUMERPRICES_CHANGED } from './actions';
import { createReducer } from 'utils/redux';

const initialState = {
  isShowConsumerPrices: false,
};

export default createReducer(initialState, {
  [ISSHOWCONSUMERPRICES_CHANGED]: onIsShowConsumerPricesChanged,
});

function onIsShowConsumerPricesChanged(state, action) {
  return {
    ...state,
    isShowConsumerPrices: action.payload,
  };
}