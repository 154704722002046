//3.3.My account � Open order lines, status update
import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { STATUSUPDATE_SUBMITTED, statusUpdateReceived, StatusUpdateAction } from './actions';
import { statusUpdateMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';

type StatusUpdateActionMutationResponse = {
  statusUpdate: boolean;
}

const statusUpdateEpic: Epic<StatusUpdateAction> = (action$, _, { api, logger }) => action$.pipe(
    ofType(STATUSUPDATE_SUBMITTED),
    switchMap(action => api.graphApi<StatusUpdateActionMutationResponse>(statusUpdateMutation, { statusUpdateData: action.payload }, { retries: 0 }).pipe(
        mergeMap(result => [statusUpdateReceived(result.statusUpdate), resetCaptcha(), unlockForm(FormLockKeys.StatusUpdate)]),
        catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(), unlockForm(FormLockKeys.StatusUpdate))),
        retryWithToast(action$, logger, _ => of(resetCaptcha(), unlockForm(FormLockKeys.StatusUpdate))),
    )),
);

export default statusUpdateEpic;
