import type { Filter, DocumentsCollection, DocumentsResponse } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { initPageContent, initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestDocuments } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';
import { loadSystemPageQuery } from '../system/queries';
import { getDocumentField, DocumentType } from 'behavior/documents';

export const size = 10;

export default function (documentType: DocumentType, componentName: PageComponentNames, query: string) {
  const handler: Handler<DocumentsRouteData, DocumentsPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
    const filter = normalizeFilter(params && params.filter);

    if (params?.previewToken) {
      const documentsFieldName = getDocumentField(documentType);

      return api.graphApi<DocumentsPreviewPageResponse>(loadSystemPageQuery(documentsFieldName)).pipe(
        map(({ pages }) => {
          const page = pages[documentsFieldName];
          if (!page)
            return null;

          return {
            page: {
              component: componentName,
              docs: { totalCount: 0, items: [] },
              size,
              filter,
              ...page,
            },
          };
        }),
        initSystemPageContent(),
      );
    }

    const onlyItems = pageOptions && pageOptions.onlyItems;
    const backTo = pageOptions && pageOptions.backTo;
    const options = createOptions(params, filter, onlyItems);

    if (onlyItems)
      return of({
        action$: of(requestDocuments(options, documentType)),
        page: {
          ...state$.value.page as DocumentsPage,
          filter,
        },
      });

    return api.graphApi<DocumentsPageResponse>(query, { options }).pipe(
      map(({ pages: { page }, documents: pageDocuments }) => {
        if (!page)
          return null;
        const initiatedPage = initPageContent(page);
        const documents = pageDocuments.docs && pageDocuments.docs.list;

        if (!documents) {
          return {
            page: {
              component: PageComponentNames.NotFound,
              ...initiatedPage,
            } as NotFoundPage,
          };
        }

        const resultPage = {
          component: componentName,
          docs: documents,
          size,
          filter,
          backTo,
          ...initiatedPage,
        } as DocumentsPage;

        return { page: resultPage };
      }),
    );
  };

  return handler;
}

export function createOptions(params?: Params, filter?: Filter, onlyItems?: boolean) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  return { ...filter, page };
}

export function normalizeFilter(filter?: Filter) {
  if (filter && filter.orderDate)
    return filter;

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);
  //____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
  const status = {
      backorder: true,
      denied: true,
      open: true,
      referral: true,
      sent: true,
      reserved: true,
  };
    return { ...filter, orderDate: { from: DateOnly.toISOString(from) }, ...status };
}

function getNumberOfDaysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

type Params = {
  filter: Filter;
  index?: number;
  previewToken?: string;
}

type DocumentsRouteData = {
  params?: Params;
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type DocumentsPreviewPageResponse = {
  pages: {
    [documents in ReturnType<typeof getDocumentField>]: SystemPageData;
  };
}

type DocumentsPageResponse = {
  pages: {
    page: SystemPageData;
  };
} & DocumentsResponse;

type DocumentsPage = SystemPage & {
  component: PageComponentNames;
  docs: DocumentsCollection;
  size: number;
  filter: Filter;
  backTo?: BackTo;
}
