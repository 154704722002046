//213513_2.1_CarScreenPage
import { ResultsCollection } from './types';
import { CAR_SCREENS_RECEIVED, CarScreensReceivedAction } from './actions';
import { createReducer } from 'utils/redux';

type State = {
  list: ResultsCollection | null;
};

export default createReducer<State, CarScreensReceivedAction>(null as any, {
  [CAR_SCREENS_RECEIVED]: onLineDocumentsReceived,
});

function onLineDocumentsReceived(state: State, action: CarScreensReceivedAction) {
  const {
    list,
    page,
  } = action.payload;

  if (!state.list || page === 0 || !list) {
    return {
      ...state,
      list,
    };
  }

  const { items, totalCount } = list;
  return {
    ...state,
    list: {
      items: state.list.items.concat(items),
      totalCount,
    },
  };
}
