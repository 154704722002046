import { mergeReducers } from 'utils/redux';
import adminTextsReducer from 'behavior/adminTexts/reducer';
import appReducer from 'behavior/app/reducer';
import ariaStatusReducer from 'behavior/ariaStatus/reducer';
import settingsReducer from 'behavior/settings/reducer';
import routingReducer from 'behavior/routing/reducer';
import sanaTextReducer from 'behavior/sanaText/reducer';
import userReducer from 'behavior/user/reducer';
import navigationReducer from 'behavior/navigation/reducer';
import newsHeadlinesReducer from 'behavior/newsHeadlines/reducer';
import newsReducer from 'behavior/pages/news/reducer';
import changePasswordReducer from 'behavior/pages/changePassword/reducer';
import newsletterReducer from 'behavior/newsletter/reducer';
import pageGenericReducer from 'behavior/pages/reducer';
import contactUsReducer from 'behavior/pages/contactUs/reducer';
//3.3.My account � Open order lines, status update.
import statusUpdateReducer from 'behavior/pages/statusUpdate/reducer';
import captchaReducer from 'behavior/captcha/reducer';
import localizationReducer from 'behavior/localization/reducer';
import themeReducer from 'behavior/theme/reducer';
import searchReducer from 'behavior/products/search/suggestions/reducer';
import visualDesignerReducer from 'behavior/visualDesigner/reducer';
import insiteEditorReducer from 'behavior/insiteEditor/reducer';
import visualDesignerPageReducer from 'behavior/pages/visualDesigner/pageReducer';
import productReducer from 'behavior/pages/product/reducer';
import representationPageReducer from 'behavior/pages/representation/reducer';
import connectionReducer from 'behavior/connection/reducer';
import basketReducer from 'behavior/basket/reducer';
import childMenuItemsReducer from 'behavior/content/childMenuItems/reducer';
import productListReducer from 'behavior/pages/productList/reducer';
import basketPageReducer from 'behavior/pages/basket/reducer';
import sortableFieldsReducer from 'behavior/products/search/sortableFields/reducer';
import lastViewedPageReducer from 'behavior/pages/productList/lastViewed/reducer';
import lastViewedReducer from 'behavior/products/lastViewedTracking/reducer';
import orderTemplatesReducer from 'behavior/pages/orderTemplates/reducer';
import salesAgreementsPageReducer from 'behavior/pages/salesAgreements/reducer';
import productConfiguratorReducer from 'behavior/productConfigurator/reducer';
import loadingIndicatorReducer from 'behavior/loadingIndicator/reducer';
import documentsReducer from 'behavior/pages/documents/reducer';
import productComparisonReducer from 'behavior/productComparison/reducer';
import documentReducer from 'behavior/pages/document/reducer';
import checkoutOffersReducer from 'behavior/checkoutOffers/reducer';
import forgotPasswordReducer from 'behavior/pages/forgotPassword/reducer';
import resetPasswordReducer from 'behavior/pages/resetPassword/reducer';
import registrationReducer from 'behavior/pages/registration/reducer';
import createProspectReducer from 'behavior/pages/createProspect/reducer';
import profilerReducer from 'behavior/tools/profiler/reducer';
import subAccountsReducer from 'behavior/pages/subAccounts/reducer';
import productComparisonPageReducer from 'behavior/pages/productComparison/reducer';
import productSetsReducer from 'behavior/content/productSets/reducer';
import checkoutReducer from 'behavior/pages/checkout/reducer';
import analyticsReducer from 'behavior/analytics/reducer';
import orderAuthorizationsReducer from 'behavior/pages/orderAuthorizations/reducer';
import invoicePaymentReducer from 'behavior/pages/invoicePayment/reducer';
import productSelectorReducer from 'behavior/productSelector/reducer';
import createDocFreeReturnOrderReducer from 'behavior/pages/createDocFreeReturnOrder/reducer';
import createDocBasedReturnOrderReducer from 'behavior/pages/createDocBasedReturnOrder/reducer';
import wishListReducer from 'behavior/wishList/reducer';
import wishListPageReducer from 'behavior/pages/wishList/reducer';
import errorReducer from 'behavior/errorHandling/reducer';
import orderPaymentReducer from 'behavior/pages/orderPayment/reducer';
import headerReducer from 'behavior/header/reducer';
import footerReducer from 'behavior/footer/reducer';
import representationSubDealerPageReducer from 'behavior/pages/representationDealer/reducer'; // 3.7.Shop account-Main dealer
import myResultsReducer from 'behavior/pages/myResults/reducer'; //3.8.My account-My results
import carScreensReducer from 'behavior/pages/carScreens/reducer'; //213513_2.1_CarScreenPage
import facetImageReducer from 'behavior/pages/productList/facetImage/reducer'; // 4.2.Facetted filters � Show images for model filter
import isShowConsumerPricesReducer from 'behavior/consumerPriceSelector/reducer';

const pageReducer = mergeReducers(
  pageGenericReducer,
  newsReducer,
  changePasswordReducer,
  contactUsReducer,
  //3.3.My account � Open order lines, status update
  statusUpdateReducer,
  visualDesignerPageReducer,
  productReducer,
  productListReducer,
  basketPageReducer,
  representationPageReducer,
  childMenuItemsReducer,
  lastViewedPageReducer,
  documentsReducer,
  documentReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  registrationReducer,
  createProspectReducer,
  salesAgreementsPageReducer,
  subAccountsReducer,
  productComparisonPageReducer,
  productSetsReducer,
  checkoutReducer,
  orderAuthorizationsReducer,
  invoicePaymentReducer,
  createDocFreeReturnOrderReducer,
  createDocBasedReturnOrderReducer,
  orderPaymentReducer,
  wishListPageReducer,
  productConfiguratorReducer,
  representationSubDealerPageReducer, // 3.7.Shop account-Main dealer
  myResultsReducer,//3.8.My account-My results
  carScreensReducer,/*213513_2.1_CarScreenPage*/
  facetImageReducer,// 4.2.Facetted filters � Show images for model filter
);

export default {
  adminTexts: adminTextsReducer,
  analytics: analyticsReducer,
  app: appReducer,
  ariaStatus: ariaStatusReducer,
  basket: basketReducer,
  captcha: captchaReducer,
  checkoutOffers: checkoutOffersReducer,
  connection: connectionReducer,
  error: errorReducer,
  footer: footerReducer,
  header: headerReducer,
  insiteEditor: insiteEditorReducer,
  isLoading: loadingIndicatorReducer,
  lastViewed: lastViewedReducer,
  localization: localizationReducer,
  navigation: navigationReducer,
  newsHeadlines: newsHeadlinesReducer,
  newsletter: newsletterReducer,
  orderTemplates: orderTemplatesReducer,
  page: pageReducer,
  productComparison: productComparisonReducer,
  productSelector: productSelectorReducer,
  profiler: profilerReducer,
  routing: routingReducer,
  sanaTexts: sanaTextReducer,
  settings: settingsReducer,
  sortableFields: sortableFieldsReducer,
  suggestions: searchReducer,
  theme: themeReducer,
  user: userReducer,
  visualDesigner: visualDesignerReducer,
  wishList: wishListReducer,
  //131754 [HyundaiMotorNetherlands] 4.4.	Header � Show consumer prices.
  isShowConsumerPrices: isShowConsumerPricesReducer,
};
