import type { Document } from './types';
import { QuoteStatus, DocumentType } from './constants';

export const isDocumentCancelled = (document: Document) =>
  document.status === QuoteStatus.Cancelled || document.status === 'CANCELLED';

export const isDocumentPromotableQuote = (document: Document, documentType: keyof typeof DocumentType) =>
  documentType === DocumentType.Quote && document.status === QuoteStatus.Orderable;

const fieldPerDocumentType = {
  [DocumentType.Order]: 'orders',
  //____ [HyundaiMotorNetherlands] 3.2. My account – Open order lines.
  [DocumentType.OpenOrderLines]: 'openOrderLines',
  //____ [HyundaiMotorNetherlands] 3.5.My account – Credit note line overview.
  [DocumentType.CreditNoteLines]: 'creditNoteLines',
  [DocumentType.Quote]: 'quotes',
  [DocumentType.Invoice]: 'invoices',
  [DocumentType.ReturnOrder]: 'returnOrders',
  [DocumentType.CreditNote]: 'creditNotes',
  [DocumentType.ReturnReceipt]: 'returnReceipts',
  [DocumentType.Shipment]: 'shipments',
  [DocumentType.InvoiceLines]: 'invoiceLines',//TICKET 131741- 3.4. My account – Sales invoice line history
} as const;

export function getDocumentField(documentType: DocumentType) {
  return fieldPerDocumentType[documentType];
}

const documentDetailsFieldMap = {
  [DocumentType.Order]: 'orderDetails',
  [DocumentType.Quote]: 'quoteDetails',
  [DocumentType.Invoice]: 'invoiceDetails',
  [DocumentType.ReturnOrder]: 'returnOrderDetails',
  [DocumentType.CreditNote]: 'creditNoteDetails',
  [DocumentType.ReturnReceipt]: 'returnReceiptDetails',
  [DocumentType.Shipment]: 'shipmentDetails',
} as const;

export function getDocumentDetailsField(documentType: keyof typeof documentDetailsFieldMap) {
  return documentDetailsFieldMap[documentType];
}
