export const enum FormLockKeys {
  Registration = 'registration',
  SubAccount = 'subAccount',
  ChangePassword = 'changePassword',
  ContactUs = 'contactUs',
  //3.3.My account – Open order lines, status update
  StatusUpdate= 'statusUpdate',
  Review = 'review',
  ForgotPassword = 'forgotPassword',
  SaveOrderTemplate = 'saveOrderTemplate',
  CreateProspect = 'createProspect',
  EditProfile = 'editProfile',
  CreateDocFreeReturnOrder = 'createDocFreeReturnOrder',
  CreateDocBasedReturnOrder = 'createDocBasedReturnOrder',
}
