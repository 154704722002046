//139429 [HyundaiMotorNetherlands] Header – Show representing account ID.
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { connect } from 'react-redux';

const DealerInfoBlock = ({ isDesignerMode, dealerNumber, isImpersonating, dealerName }) => {

  if (isDesignerMode)
    return (
      <>
        <span>{'Representing'}<b>{' 012345 | sana user'}</b></span>
      </>
    );

  return (
    dealerNumber ? <><SimpleText textKey="Representing" /><span><b>{` ${dealerNumber} | ${dealerName}`}</b></span></> : null
  );
};

DealerInfoBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
  dealerNumber: PropTypes.string,
  isImpersonating: PropTypes.bool,
  dealerName: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  dealerNumber: user.customer?.id,
  dealerName: user.customer?.name,
  isImpersonating: user.isImpersonating,
});

export default connect(mapStateToProps)(DealerInfoBlock);