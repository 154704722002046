import type { Filter, ResultsResponse, ResultsCollection } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestMyResults } from './actions';
import { PageComponentNames } from '../componentNames';

export const size = 10;

export default function (componentName: PageComponentNames, query: string) {
  const handler: Handler<MyResultsRouteData, MyResultsPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
    const filter = params?.filter;

    const onlyItems = pageOptions && pageOptions.onlyItems;
    const backTo = pageOptions && pageOptions.backTo;
    const options = createOptions(params, filter, onlyItems);

    if (onlyItems)
      return of({
        action$: of(requestMyResults(options)),
        page: {
          ...state$.value.page as MyResultsPage,
          filter,
        },
      });

    return api.graphApi<PageResponse>(query, { options }).pipe(
      map(({ pages: { page }, myResults }) => {
        if (!page)
          return null;

        const initiatedPage = initPageContent(page);
        const documents = myResults && myResults.list;

        if (!documents) {
          return {
            page: {
              component: PageComponentNames.NotFound,
              ...initiatedPage,
            } as NotFoundPage,
          };
        }

        const resultPage = {
          component: componentName,
          list: documents,
          size,
          filter,
          backTo,
          ...initiatedPage,
        } as MyResultsPage;

        return { page: resultPage };
      }),
    );
  };

  return handler;
}

export function createOptions(params?: Params, filter?: Filter, onlyItems?: boolean) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };
  if (!filter) {
    const initialFilter: Filter = {
      showConsolidatedResults: true,
    };

    filter = { ...initialFilter };
  }
  return { ...filter, page };
}

type Params = {
  filter?: Filter;
  index?: number;
  previewToken?: string;
}

type MyResultsRouteData = {
  params?: Params;
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type PageResponse = {
  pages: {
    page: SystemPageData;
  };
} & ResultsResponse;

type MyResultsPage = SystemPage & {
  component: PageComponentNames;
  list: ResultsCollection;
  size: number;
  filter?: Filter;
  backTo?: BackTo;
}
