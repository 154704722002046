//213513_2.1_CarScreenPage
import type { Epic } from 'behavior/types';
import { CAR_SCREENS_REQUESTED, carScreensReceived, CarScreensAction } from './actions';
import { carScreensQuery } from './queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { ResultsResponse } from './types';

const carScreensEpic: Epic<CarScreensAction> = (action$, _, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();
  return action$.pipe(
    ofType(CAR_SCREENS_REQUESTED),
    switchMap(({ payload: { options } }) => {
      return api.graphApi<ResultsResponse>(carScreensQuery, { options }).pipe(
        pluck('carScreens'),
        mergeMap((results: any) => of(
          carScreensReceived(results.list, options.page.index),
          unsetLoading,
        )),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        takeUntil(locationChanged$),
        startWith(setLoading),
      );
    }),
  );
};

export default carScreensEpic;
