import {
  FacetImageTypeAction,
  FACET_IMAGE_REQUEST,
  receivedFacetImage,
} from './actions';
import { switchMap, map } from 'rxjs/operators';
import { requestFacetImageQuery } from './queries';
import { EMPTY, merge } from 'rxjs';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';
import { ofType } from 'redux-observable';
import { Epic } from 'behavior/types';

const facetImageEpic: Epic<FacetImageTypeAction> = (action$, _state$, dependencies) => {
  const { api } = dependencies;
  const requestFacetImage$ = action$.pipe(
    ofType(FACET_IMAGE_REQUEST),
    switchMap(action =>
      api.graphApi(requestFacetImageQuery, { filter: action.payload }, { retries: 0 }).pipe(
        map(data => receivedFacetImage({ ...data })),
        handleError(ERROR_PRIORITIES.MEDIUM, 'FacetImage', () => EMPTY),
      )),
  );

  return merge(requestFacetImage$);
};

export default facetImageEpic;