//131754 [HyundaiMotorNetherlands] 4.4.	Header – Show consumer prices.
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { isShowConsumerPricesChanged } from 'behavior/consumerPriceSelector/actions';
import { RichText } from 'components/sanaText';
import { Button } from '../../primitives/buttons';

const ConsumerPriceSelector = ({ isDesignerMode, isShowConsumerPricesChanged }) => {

  const text1 = {
    text: 'IsShowConsumerPrices_On',
    buttonStype: 'mode-on',
  };
  const text2 = {
    text: 'IsShowConsumerPrices_Off',
    buttonStype: 'mode-off',
  };

  if (isDesignerMode)
    return (
      <>
        <Button className={text1.buttonStype}><RichText textKey={text1.text} /></Button>
      </>
    );
  const { isShowConsumerPrices, initialValue } = useSelector(state => {
    return { isShowConsumerPrices: state.isShowConsumerPrices.isShowConsumerPrices, initialValue: state.user.isShowConsumerPrices };
  });
  const [selectedIsShowConsumerPrices, setSelectedIsShowConsumerPrices] = useState(initialValue === isShowConsumerPrices ? isShowConsumerPrices : initialValue); 

  const onChange = useCallback(e => {
    e.persist();
    isShowConsumerPricesChanged(!selectedIsShowConsumerPrices);
    setSelectedIsShowConsumerPrices(!selectedIsShowConsumerPrices);
  }, [selectedIsShowConsumerPrices]);

  return (
    <Button onClick={onChange} className={selectedIsShowConsumerPrices ? text1.buttonStype : text2.buttonStype}>
      <RichText textKey={selectedIsShowConsumerPrices ? text1.text : text2.text} />
    </Button>
  );
};

ConsumerPriceSelector.propTypes = {
  isDesignerMode: PropTypes.bool,
  isShowConsumerPricesChanged: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  isShowConsumerPricesChanged,
};

export default connect(null, mapDispatchToProps)(ConsumerPriceSelector);