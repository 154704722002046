////213513_2.1_CarScreenPage
import loadable from '@loadable/component';

const CarScreens = loadable(() => import(/*webpackChunkName:"docs"*/'./CarScreens'));

function selectPropsFromPage({
  list: {
    items,
    totalCount,
  },
  size,
  filter,
}) {
  return {
    list: items,
    totalCount,
    size,
    filter,
  };
}
// hyundai 3.8
export function carScreensRenderer(page) {
  return <CarScreens {...selectPropsFromPage(page)} />;
}